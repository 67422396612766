/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { headerLinks } from "../config"
import { Link } from "gatsby"

import Header from "./header"
import "./common.scss"
import "./custom.scss"
import "./style.scss"

import facebook from "../assets/images/facebook.png"
import twitter from "../assets/images/twitter.png"
import linkedIn from "../assets/images/linkedin-logo.png"
import machLogo from "../assets/images/logo.png"

const listItems = headerLinks.map((link, index) => {
  console.log(link.hasOwnProperty('subHead'))
  return (<div>
    { link.hasOwnProperty('subHead') ? (
      <Link
        to={link.url}
        key={link.name}
        activeClassName="active-link"
        className={
          index % 2 === 0
            ? "text-capitalize px-2 footer-link col-6 col-md-7 text-left py-1 pt-2"
            : "text-capitalize px-2 footer-link alignText text-left py-1 pt-2"
        }
      >
        {index % 2 === 0 ? <div>{link.name}</div> : <div>{link.name}</div>}
      </Link>

    ) : (<Link
      to={link.url}
      key={link.name}
      activeClassName="active-link"
      className={
        index % 2 === 0
          ? "text-capitalize px-2 footer-link col-6 col-md-7 text-left py-1 pt-2"
          : "text-capitalize px-2 footer-link alignText text-left py-1 pt-2"
      }
    >
      {index % 2 === 0 ? <div>{link.name}</div> : <div>{link.name}</div>}
    </Link>)}
  </div>)
}
)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div style={{ marginTop: "80px" }}>
        <main>{children}</main>
        <div style={{ background: "#FAFAFA" }} className="footer-section">
          <footer className=" container py-2 pt-lg-5 pt-md-2">
            <div className="row m-0 justify-content-between footer-div">
              {/* <div className="order-lg-2 col-lg-4 col-md-4 col-sm-12 footerDesc">
                <div
                  className="row m-0 footerNav"
                  style={{ color: "black", textAlign: "center" }}
                >
                 
                </div>
              </div> */}
              <div className="col-12 col-md-3 col-lg-3 pl-0 footer-img-div">
                <img
                  src={machLogo}
                  alt="Logo"
                  className="img-fluid img-footer h-70"
                />
                <p className="footer m-0 pl-0 pt-2 pb-2 footer-para">
                  Copyright © 2020 Machstatz
                </p>
                <div className="share-icons pr-md-2 text-lg-left text-sm-center">
                  <a
                    className="share-icon"
                    target="_blank"
                    href="https://www.linkedin.com/company/machstatz-business-solutions-pvt.-ltd./"
                  >
                    <img className="icons" src={linkedIn}></img>
                  </a>

                  <a
                    className="share-icon"
                    target="_blank"
                    href="https://www.facebook.com/machstatz/"
                  >
                    {/* <i className="fa fa-linkedin fa-lg footer-icon pl-2"></i> */}

                    <img className="icons" src={facebook}></img>
                  </a>
                  {/* <a className="share-icon" target="_blank" href="">
                  

                  <img className="icons" src={instagram}></img>
                </a> */}
                  <a
                    className="share-icon"
                    target="_blank"
                    href="https://twitter.com/machstatz?lang=en"
                  >
                    {/* <i className="fa fa-facebook-f fa-lg footer-icon"></i> */}

                    <img className="icons" src={twitter}></img>
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 pt-3 pt-md-0">
                <div className="m-0 pt-lg-1 pt-md-0">
                  <p className="font-14 p-0 m-0 text-center">Head Office</p>
                  <p className="footer-address text-center">
                    Machstatz c/o DERBI, Dayananda Sagar University Campus, 2nd
                    Floor, Block 1, Kudlu Gate, Hongasandra Village, Hosur Rd
                    Bengaluru, Karnataka 560068
                  </p>
                  
                  {/* <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                    <p style={{marginRight:'5px'}}><i class="fas fa-phone-alt"></i></p>
                    <p style={{marginRight:'5px'}}>:</p>
                    <p style={{marginRight:'5px'}}>080 XXXXXX</p>
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 pt-3 pt-md-0">
                <div className="m-0 pt-lg-1 pt-md-0">
                  <p className="font-14 p-0 m-0 text-center">Branch Office</p>
                  <p className="footer-address text-center">
                    Machstatz c/o International Institute Information Technology campus,
                    Professor CR Rao Rd, Gachibowli,
                    Hyderabad, Telangana 500032
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 pt-3 pt-md-0">
                <div className="m-0 pt-lg-1 pt-md-0">
                  <p className="font-14 p-0 m-0 text-center">Contact Us At</p>
                  <p className="footer-address text-center">
                  
                  {/* <span style={{marginRight:'5px'}}><i class="fas fa-envelope"></i></span>
                  <span style={{marginRight:'5px'}}>:</span> */}
                  <div className=''>
                  <span><i class="fas fa-envelope"></i></span>
                      <p style={{marginRight:'5px',marginBottom:'-5px'}}>info@machstatz.com,</p>
                      <p style={{marginRight:'5px',marginBottom:'-5px'}}>sales@machstatz.com,</p>
                      <p style={{marginRight:'5px'}}> hr@machstatz.com</p>
                  </div>
                  {/* <div>
                  <i class="fas fa-phone-alt"></i>
                   <p style={{marginBottom:'-5px'}}>7847949998</p>
                   <p>9818047258</p>
                  </div> */}
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
