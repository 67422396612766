export const headerLinks = [
  {
    name: "home",
    url: "/",
  },
  {
    name: "solutions",
    url: "/solutions/",
  },
  {
    name: "products",
    url: "/products/",
    subHead:[
      {
        name: "FlashIO – IoT Kit",
        url: "/products/flashIO-iot-kit/",
        id: "/products/flashIO-iot-kit/"
      },
      {
        name: "4TellAi",
        url: "/products/4tell-ai/",
        id: "/products/4tell-ai/"
      },
      {
        name: "Lucrys Analytics",
        url: "/products/lucrys-analytics/",
        id: "/products/lucrys-analytics/"
      },
      {
        name: "eSave",
        url: "/products/eSave/",
        id: "/products/eSave/"
      },
    ]
  },
  {
    name: "Industries",
    url: "/industries/",
  },
  // {
  //   name: 'Resource',
  //   url: '/resources/',
  //   module: 'resource',
  //   subHead: [{
  //     name: "Case Studies",
  //     url: "/resources/case-studies/",
  //     id: "/resources/case-studies/",
  //     options: [
  //       {
  //         name:'JSPL',
  //         url:'/resources/case-studies/jspl',
  //         id:'/resources/case-studies/jspl'
  //       },
  //       {
  //         name:'KMML',
  //         url:'/resources/case-studies/kmml',
  //         id:'/resources/case-studies/kmml'
  //       },
  //       {
  //         name:'Stanzen',
  //         url:'/resources/case-studies/stanzen',
  //         id:'/resources/case-studies/stanzen'
  //       },
  //     ]
  //   },
  //   {
  //     name: "Blogs",
  //     url: "/resources/blogs/",
  //     id: "/resources/blogs/",
  //     options: [
    //       {
      //         name:'AI can solve challenges',
      //         url:'/resources/blogs/How_Artificial_Intelligence_Can_Solve_Manufacturing_Challenges_during_Covid-19',
      //         id:'/resources/blogs/How_Artificial_Intelligence_Can_Solve_Manufacturing_Challenges_during_Covid-19'
      //       }
      //     ]
      //   }]
      // },
      {
        name: "Careers",
        url: "https://machstatz.zohorecruit.com/careers",
        id: "careers",
      type: 'external-link'
      },
  {
    name: "About Us",
    url: "/about-us/",
    subHead: [
      {
        name: "Our Partners",
        url: "",
        id: "/about-us/#tech"
      },
      {
        name: "Our Investors",
        url: "/about-us/investors",
        id: "/about-us/investors"
      },
      {
        name: "Contact Us",
        url: "/contact-us/",
        id: "/contact-us/"
      },
      {
        name: "Awards & Press",
        url: "/awards-and-press/",
        id: "/awards-and-press/"
      },
      // {
      //   name: "Careers",
      //   url: "https://machstatz.zohorecruit.com/careers",
      //   id: "careers",
      //   type: 'external-link'
      // },
    ],
  },
  // {
  //   name: "Contact Us",
  //   url: "/contact-us/",
  // },

]

export const homePageFetaure = [
  {
    prefix: "",
    value: "150",
    unit: "+",
    name: "Connected Machines",
  },
  {
    prefix: "",
    value: "50",
    unit: "Mn+",
    name: "Data Processed /Hr",
  },
  {
    prefix: "upto",
    value: "95",
    unit: "%",
    name: "Reduction in Manual Processes",
  },
  {
    prefix: "$",
    value: "5",
    unit: "Mn",
    name: "ROI on Development",
  },
]

export const ourClients = [
  {
    logoSrc: "capgemini.svg",
    clientName: "Capgemini",
  },
  {
    logoSrc: "borosil.png",
    clientName: "Borosil.",
  },
  {
    logoSrc: "pedilite.png",
    clientName: "Pedilite",
  },
  {
    logoSrc: "stanzen.jpg",
    clientName: "Stanzen",
  },
  {
    logoSrc: "jspl.png",
    clientName: "JSPL",
  },
]

export const featureHighlights = [
  {
    featureIcon: "/static/svgs/Edge.svg",
    featureName: "Edge",
    featureDesc:
      "Accurate and fudge-free data can be the difference between success and failure. Now you can monitor production progress 24x7 using state of the art industrial IoT technology.",
    featureHighlights: [
      "Custom dashboards and reports",
      "Production analytics",
      "Real-time quality checks",
      "On-premise deployment",
    ],
  },
  {
    featureIcon: "/static/svgs/remote-access.svg",
    featureName: "Eagle eye",
    featureDesc:
      "Frequent plant visits are eating into your productive time? <br>Access all production and plant data from anywhere and at any time, using our gateway technology.",
    featureHighlights: [
      "Remote access",
      "Cloud deployment and storage",
      "Real-time analytics",
      "Custom dashboards & reports",
    ],
  },
  {
    featureIcon: "/static/svgs/Acute1.svg",
    featureName: "Acute",
    featureDesc:
      "Accurately forecast production units, idle time, machine failures, completion time and much more using our advanced machine learning and artificial technology.",
    featureHighlights: [
      "Predictive & advanced analytics",
      "Remaining Useful Life & Root Cause Analysis",
      "Auto-detection of shutdowns",
      "Remote access",
    ],
  },
]
