import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { headerLinks } from "../config"
import { navigate } from "gatsby"
import './header.scss'
import machLogo from "../assets/images/logo.png"

const checkActive = (match, location, link) => {
  //some additional logic to verify you are in the home URI
  console.log('check active', match, location, link);
  if (!location) return false;
  const { pathname } = location;
  return pathname === "/";
}

const listItems = headerLinks.map((link, index) => {
  console.log(link.hasOwnProperty('subHead'))
  return (
    <React.Fragment key={link.name}>
      <div className={`${link.hasOwnProperty('subHead') ? 'dropdown' : ''}`}>
        <Link
          to={link.url}
          key={link.name}
          partiallyActive={link.url!=="/"}
          activeClassName="active-link"
          className={
            `text-capitalize px-2 footer-link text-left py-1 pt-2 ${index % 2 === 0 ? 'alignText' : ''}`
          }
        >
          {link.name}
        </Link>
        {
          link.hasOwnProperty('subHead') ? (
            <React.Fragment>
            <div class="dropdown-content" >
              {link.subHead.map(item => {
                return (
                  <div class="content-wrapper">
                  <p key={item.id}>
                    {
                      item.type === 'external-link' ?
                        <a href={item.url} target="_blank">{item.name}</a>
                       : <Link to={item.id} key={item.name} activeClassName="active-sub-link">{item.name}</Link>
                    }
                  </p>
                  <div class="subLink-dropdown">
                    {item.hasOwnProperty('options')?item.options.map((ops)=>{
                      return (<React.Fragment><Link to={ops.id} key={ops.name} activeClassName="active-sub-link">{ops.name}</Link><br/></React.Fragment>)
                    }):""}
                  </div>
                  </div>
                  )
              })}
            </div>
            <div class="dropdown-content2">
            {link.subHead.map(item => {
                return (
                  <p key={item.id}>
                    {
                      item.type === 'external-link' ?
                        <a href={item.url} target="_blank">{item.name}</a>
                       : <Link to={item.id} key={item.name}>{item.name}</Link>
                    }
                  </p>
                  )
              })}
            </div>
            </React.Fragment>
          ) : null
        }
      </div>
    </React.Fragment>
  )
})


const requestDemoHandler = () => {
  console.log("request demo handler")
  navigate("/#lets-talk", { state: { sentToLetstalk: true } })
  // if(document.getElementById('lets-talk')) {
  //     navigate('#lets-talk')
  // }
}

const Header = ({ siteTitle }) => (
  <div className="site-header">
    <header className="container">
      <nav className="navbar p-0 navbar-expand-lg navbar-light m-0">
        <a className="navbar-brand" href="/">
          <img src={machLogo} alt="Logo" className="img-fluid" />
        </a>
        <div>
          <button
            type="button"
            className="d-lg-none request-button request-button-res mr-3"
            onClick={requestDemoHandler}
          >
            Request Demo
          </button>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <span className="navbar-toggler-icon"></span> */}
            <div className="navbarToggle"></div>
            <div className="navbarToggle"></div>
            <div className="navbarToggle"></div>
          </button>
        </div>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav links-container">{listItems}</ul>
        </div>
        <div className="d-none d-lg-block">
          <button
            type="button"
            className="btn request-button"
            onClick={requestDemoHandler}
          >
            Request Demo
          </button>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
    </header>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
